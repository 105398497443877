import React from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';


const Drop_nav = () => {

  // Creating a state to toggle the current drop-nav link clicked
  const [activeIndex, setActiveIndex] = useState();


  // This state contains the role of admin
  const adminState = useSelector(state => state.admin.admin);

  const handleNavClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <StyledDropNav>
      <ul className='drop-nav-list'>
        {adminState && adminState == 1 && (
          <NavLink to='/Admin'>
            <li className="nav-link">Admin</li>
          </NavLink>
        )}
        <NavLink to='/Backup'>
          <li className="nav-link">Backup</li>
        </NavLink>


        <li className="nav-link">

        </li>
        <li className="nav-link">

        </li>


      </ul>
    </StyledDropNav >
  )
}
const StyledDropNav = styled.div`
    
    .drop-nav-link {
    list-style: none;
    margin-top: 15px;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .drop-nav-link:hover {
    background-color: #FFA2A6;
  }

  .drop-nav-link.active {
    background-color: #8E3B3E;
    color: white;
  }

  .drop-nav-list{
    margin-left: 20px;

  }
`
export default Drop_nav