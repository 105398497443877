import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Sidebar from '../Sidebar';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faFileUpload, faFileDownload } from '@fortawesome/free-solid-svg-icons';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { secretKey, apiUrl } from '../constant';
import { encryptData, decryptData } from '../Functions/Data_protection';
import { handleExportExcel } from '../Functions/Excelprint';

import { HandleDelete } from '../api/Deletion';
import { validateForm } from '../Functions/Form_validation';
import { handleAdd } from '../api/Addition';
import { handleFileUpload } from '../Functions/Excelupload';
import { handlefilter } from '../Functions/FilterRecords';

import { groupload } from '../api/Groupload';
import Header from '../Components/Mainpage_componnets/Header';


import { addledger } from '../store/slices/LedgerSlice';
import { updateledger } from '../store/slices/LedgerSlice';
import { removeledger } from '../store/slices/LedgerSlice';







const Ledger = () => {

    const dispatch = useDispatch();

    const adminidState = useSelector(state => state.admin.userid);




    const federationlistState = useSelector(state => state.federation.federationlist);
    const buildinglistState = useSelector(state => state.building.buildinglist);
    const selectedbuildingState = useSelector(state => state.building.selectedbuilding);


    const ledgerlistState = useSelector(state => state.ledger.ledgerlist);





    const [originalRecords, setOriginalRecords] = useState([]);
    const [records, setRecords] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [slideropen, setSlideropen] = useState(false);
    const [ledid, setLedId] = useState('');
    const [hsgfedid, setHsgfedId] = useState('');

    const [grpid, setGrpId] = useState('');
    const [flatshopno, setFlatshopno] = useState('');
    const [propertytype, setPropertytype] = useState('');
    const [bldgno, setBldgno] = useState('');
    const [wingno, setWingno] = useState('');
    const [area, setArea] = useState('');

    const [openingbalance, setOpeningbalance] = useState('');
    const [closingbalance, setClosingbalance] = useState('');
    const [ownername, setOwnername] = useState('');
    const [mainmobileno, setMainmobileno] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [occupation, setOccupation] = useState('');
    const [errors, setErrors] = useState({});
    const [exceldata, setExceldata] = useState([]);
    const [addexcelfilebox, setAddexcelbox] = useState(false);
    const [federationlist, setFederationlist] = useState([]);
    const [localbuildinglist, setLocalbuildinglist] = useState([]);
    const [grouplist, setGrouplist] = useState([]);
    const [bldgbankname, setBldgbankname] = useState('');
    const [bldgbankaccountno, setBldgbankaccountno] = useState('');
    const [bldgbankifsc, setBldgbankifsc] = useState('');
    const [tenant, setTenant] = useState(false);
    const [twowheeler, setTwowheeler] = useState("");
    const [fourwheeler, setFourwheeler] = useState("");










    // States for handling the message box and related things
    const [smsboxexpanded, setSmsboxexpanded] = useState(true);
    const [emailboxexpanded, setEmailboxexpanded] = useState(false);
    const [whatsappboxexpanded, setWhatappboxexpanded] = useState(false);
    const [smsMessage, setSmsMessage] = useState('');
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');



    // State to store the payload of message 
    const [messageobject, setMessageobject] = useState([]);



    // States to handle message related things
    const [messageboxexpand, setMessageboxexpand] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    //References
    const hsgfedref = useRef(null);
    const bldgref = useRef(null);
    const grpref = useRef(null);




    const [updateclicked, setUpdateclicked] = useState(false);
    const [Deleteclicked, setDeleteclicked] = useState(false);



    const openslider = () => {
        setSlideropen(true);
    };

    const columns = [
        {
            name: '',
            selector: row => (
                <div onClick={() => {
                    setSlideropen(false);
                    setMessageboxexpand(false);
                    setAddexcelbox(false);
                    setDeleteclicked(true);
                    setSelectedRow(row);

                    setLedId(row.ledid);
                }}>
                    <button style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                    }}>
                        <FontAwesomeIcon icon={faClose} style={{ color: '#8E3B3E', fontSize: '1.5rem' }} />
                    </button>
                </div>
            ),
            sortable: false, // This column does not need to be sortable
            width: '50px',
            wrap: true
        },
        { name: 'Flat / Shop No', selector: row => <div onClick={() => { Updateclicked(row) }}>{row.flatshopno}</div>, sortable: true, width: '150px', wrap: true },
        { name: 'Owner Name', selector: row => row.ownername, sortable: true, wrap: true, width: '250px' },
        { name: 'Property Type', selector: row => row.propertytype, sortable: true, wrap: true, width: '250px' },
        { name: 'Building No', selector: row => row.bldgno, sortable: true, width: '150px', wrap: true },
        { name: 'Wing No', selector: row => row.wingno, sortable: true, width: '150px', wrap: true },
        { name: 'Area', selector: row => row.area, sortable: true, width: '150px', wrap: true },

    ];


    const ledgerload = async () => {
        try {
            const bldgid = selectedbuildingState.bldgid;
            const response = await axios.get(`${apiUrl}getspecificbuildingledgers`, {
                params: { bldgid }
            });

            const decryptedData = decryptData(response.data.payload, secretKey);

            setOriginalRecords(decryptedData);
            setRecords(decryptedData);


        } catch (error) {
            console.log(error);
        }

    }

    const handleLoad = async () => {
        try {
            ledgerload();

            const groupdata = await groupload("getgroups");
            setGrouplist(groupdata);

            handleDefaultAssignment(federationlistState[0].hsgfedid);

        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        handleLoad();
    }, []);

    useEffect(() => {
        ledgerload();
    }, [ledgerlistState, selectedbuildingState]);





    const handleDefaultAssignment = (selectedvalue) => {
        try {
            if (buildinglistState && federationlistState && federationlistState.length > 0) {
                const list = buildinglistState.filter(item => {
                    return item.hsgfedid === Number(selectedvalue);
                });
                setLocalbuildinglist(list);
            } else {
                console.log("buildinglistState or federationlistState is not properly populated");
            }
        } catch (error) {
            console.error("Error in handleDefaultAssignment:", error);
        }
    }









    const resetControls = () => {
        setHsgfedId('');
        setFlatshopno('');
        setPropertytype('');
        setBldgno('');
        setWingno('');
        setArea('');
        setWhatsapp('');
        setArea('');
        setMainmobileno('');
        setOccupation('');
        setOpeningbalance('');
        setClosingbalance('');
        setOwnername('');
        setBldgbankname('');
        setBldgbankaccountno('');
        setBldgbankifsc('');
        setTenant(false);
        setTwowheeler('');
        setFourwheeler('');



    };


    const Updateclicked = (row) => {
        setSelectedRow(row); // Storing the selected row data
        setDeleteclicked(false);
        setMessageboxexpand(false)
        setSlideropen(true);
        setUpdateclicked(true);



        // populate data to input
        setLedId(row.ledid);
        setGrpId(row.grpid);
        setFlatshopno(row.flatshopno);
        setPropertytype(row.propertytype);
        setBldgno(row.bldgno);
        setWingno(row.wingno);
        setArea(row.area);
        setOwnername(row.ownername);
        setMainmobileno(row.mainmobileno);
        setWhatsapp(row.whatsapp);
        setOccupation(row.occupation);
        setOpeningbalance(row.openingbalance);
        setClosingbalance(row.closingbalance);
        setBldgbankname(row.bldgbankname);
        setBldgbankaccountno(row.bldgbankaccountno);
        setBldgbankifsc(row.bldgbankifsc);
        setTenant(row.tenant);
        setTwowheeler(row.twowheeler);
        setFourwheeler(row.fourwheeler);

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload;
        console.log(hsgfedid);


        // Check if exceldata has more than one row or not
        if (exceldata.length > 1) {
            payload = exceldata.map(data => ({
                hsgfedid: data.hsgfedid,
                bldgid: data.bldgid,
                grpid: data.grpid,
                flatshopno: data.flatshopno,
                propertytype: data.propertytype,
                bldgno: data.bldgno,
                wingno: data.wingno,
                area: data.area,
                openingbalance: data.openingbalance,
                closingbalance: data.closingbalance,
                ownername: data.ownername,
                mainmobileno: data.mainmobileno,
                whatsapp: data.whatsapp,
                bldgbankname: data.bldgbankname,
                bldgbankifsc: data.bldgbankifsc,
                bldgbankaccountno: data.bldgbankaccountno,
                tenant: data.tenant,
                occupation: data.occupation,
                twowheeler: data.twowheeler,
                fourwheeler: data.fourwheeler,
                createdby: adminidState,
            }));
        } else {

            const data = exceldata[0] || {};
            payload = {


                hsgfedid: updateclicked ? null : (data.hsgfedid || federationlistState[hsgfedref.current.selectedIndex].hsgfedid),
                bldgid: updateclicked ? null : (data.bldgid || bldgref.current.value),
                grpid: updateclicked ? null : (data.grpid || grpref.current.value),
                ledid: updateclicked ? ledid : null,

                flatshopno: data.flatshopno || flatshopno,
                propertytype: data.propertytype || propertytype,
                bldgno: data.bldgno || bldgno,
                wingno: data.wingno || wingno,
                area: data.area || area,
                openingbalance: data.openingbalance || openingbalance,
                closingbalance: data.closingbalance || closingbalance,
                ownername: data.ownername || ownername,
                mainmobileno: data.mainmobileno || mainmobileno,
                whatsapp: data.whatsapp || whatsapp,
                occupation: data.occupation || occupation,
                bldgbankname: data.bldgbankname || bldgbankname,
                bldgbankifsc: data.bldgbankifsc || bldgbankifsc,
                bldgbankaccountno: data.bldgbankaccountno || bldgbankaccountno,
                tenant: data.tenant || tenant,
                twowheeler: data.twowheeler || twowheeler,
                fourwheeler: data.fourwheeler || fourwheeler,
                createdby: updateclicked ? "" : adminidState,
                modifiedby: updateclicked ? adminidState : "",
            };
        }

        console.log(`This is the payload given:`, payload);


        // let validationErrors = {};
        // if (Array.isArray(payload)) {
        //     validationErrors = payload.map(validateForm).flat();
        // } else {
        //     validationErrors = validateForm(payload);
        // }


        // if (Object.keys(validationErrors).length > 0) {
        //     console.log(validationErrors);
        //     setErrors(validationErrors);
        //     return;
        // }

        setErrors({});

        try {
            const response = await handleAdd(payload, 'addledger');
            console.log(response);
            if (response[0].status) {
                toast.success(`${response[0].message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSlideropen(false);
                setAddexcelbox(false);
                if (response[0].update) {
                    dispatch(updateledger(response[0].data));

                }
                else {
                    response.forEach(item => {
                        dispatch(addledger(item.data));
                    });


                }


            }
        } catch (error) {
            console.error(error);
        }
        finally {
            payload = null;
            setExceldata([]);


        }
    };
    const handleRowSelected = state => {
        const selectedRows = state.selectedRows;
        setSelectedRows(selectedRows);
        setMessageobject(selectedRows);


    };

    const handleSendEmail = () => {
        const messagepayload = messageobject.map(row => ({
            mainmobileno: row.mainmobileno,
            whatsapp: row.whatsapp,
            sms: smsMessage,
            whatsapp: whatsappMessage,

        }))

        console.log(messagepayload);
    };

    const HandleDeleteclicked = async () => {
        console.log(ledid);
        const deletedresponsedata = await HandleDelete(ledid, "deleteledger");
        if (deletedresponsedata.status) {
            toast.success(deletedresponsedata.message)
            setDeleteclicked(false);
            dispatch(removeledger({ ledid: deletedresponsedata.data }));




        }
    };


    return (
        <StyledLedger slideropen={slideropen}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="template-container">
                    <a href="./Templates/ledger_template.xlsx">
                        <button id='template-btn'>Download Template</button>
                    </a>
                </div>
                <div className="sub-container">
                    <header className='header'>
                        <span id='heading'>Ledgers list</span>
                        <div className="work-buttons">

                            <FontAwesomeIcon id='download-btn' icon={faFileDownload} onClick={() => handleExportExcel(selectedRows)} />

                            <FontAwesomeIcon id='upload-excel-btn' icon={faFileUpload} onClick={() => { setAddexcelbox(true); }} />

                            <input type="text" placeholder='search' id='search' onChange={(event) => { handlefilter(event, originalRecords, setRecords) }} />
                            <button id='send-mail-btn' onClick={() => {
                                if (messageobject.length >= 1) {
                                    setSlideropen(false);
                                    setDeleteclicked(false);
                                    setMessageboxexpand(true)
                                }
                                else {
                                    alert("Select the rows first")
                                }

                            }}>Send Message</button>

                            <button id='add-btn' onClick={() => {
                                setMessageboxexpand(false);
                                setAddexcelbox(false);
                                setDeleteclicked(false);
                                openslider();
                                setUpdateclicked(false); setMessageboxexpand(false); resetControls();

                            }}>Add New

                            </button>




                        </div>
                    </header>
                    <div id="table-component">
                        <DataTable
                            // ref={tableRef}
                            columns={columns}
                            data={records}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            className='data-table'
                        ></DataTable>
                    </div>


                </div>
                {addexcelfilebox && (
                    <div id="overlayaddexcelbox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setAddexcelbox(false); }}
                        />

                        <span>Upload Excel</span>
                        <input style={{ display: 'block', textAlign: 'center', border: '1px solid #8E3B3E' }} type="file" name="" id="" onChange={(e) => { handleFileUpload(e, setExceldata) }} />
                        <button id='excelbox-submit-btn' onClick={handleSubmit}>import</button>

                    </div>
                )}

                {Deleteclicked && (
                    <div className="overlaydltbox">
                        <p>Are u sure want to Delete ?</p>
                        <div className="dltresponseboxes">
                            <button id='dlt-btns' onClick={HandleDeleteclicked} >Delete</button>
                            <button id='dlt-btns' onClick={() => setDeleteclicked(false)} >Cancel</button>
                        </div>
                    </div>
                )}

                {messageboxexpand && (
                    <div className="overlaymessagebox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setMessageboxexpand(false); }}
                        />

                        <h2 style={{ color: '#8E3B3E' }}>Send Message</h2>
                        <div className="msg-checkboxes">
                            <label htmlFor="smsboxexpanded">Send SMS</label>
                            <input
                                type="checkbox"
                                id="sendSMS"
                                checked={smsboxexpanded}
                                onChange={() => setSmsboxexpanded(!smsboxexpanded)}
                            />

                            <label htmlFor="sendWhatsapp">Send Whatsapp</label>
                            <input
                                type="checkbox"
                                id="sendWhatsapp"
                                checked={whatsappboxexpanded}
                                onChange={() => setWhatappboxexpanded(!whatsappboxexpanded)}
                            />
                        </div>
                        {smsboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter SMS"
                                className="overlay-message-input"
                                value={smsMessage}
                                onChange={(e) => setSmsMessage(e.target.value)}
                            ></textarea>
                        )}
                        {whatsappboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter Whatsapp Message"
                                className="overlay-message-input"
                                value={whatsappMessage}
                                onChange={(e) => setWhatsappMessage(e.target.value)}
                            ></textarea>
                        )}

                        <button
                            className="overlay-email-submit"
                            onClick={handleSendEmail}
                        >
                            Submit
                        </button>
                    </div>
                )}

            </div>
            <div className="slider">
                <div className="icon">
                    <FontAwesomeIcon icon={faClose} id='closeicon' onClick={() => setSlideropen(false)} />
                    <p style={{ marginLeft: '2%' }}>Ledgers</p>
                </div>
                <form className="slider-form" onSubmit={handleSubmit}>
                    <label htmlFor="Name">Select Federation:</label>
                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        name="AREA"
                        id="federation_dropdown"
                        disabled={updateclicked}
                        ref={hsgfedref}
                        onChange={(e) => handleDefaultAssignment(e.target.value)}



                    >
                        {federationlistState.length > 0 &&
                            federationlistState.map((item, index) => (
                                <option
                                    key={index} value={item.hsgfedid}>
                                    {updateclicked ? "" : item.fedname}
                                </option>
                            ))}
                    </select>


                    <label htmlFor="Name">Select Building:</label>
                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        name="Building"
                        id="building_dropdown"
                        ref={bldgref}
                        disabled={updateclicked}



                    >   <option value="">select Building</option>
                        {localbuildinglist
                            .map((item, index) => (
                                <option key={index} value={item.bldgid}>
                                    {updateclicked ? "" : item.bldgname}
                                </option>
                            ))
                        }

                    </select>
                    <label htmlFor="Name">Select Group:</label>
                    <select
                        style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        name="Group"
                        id="group_dropdown"
                        ref={grpref}
                        disabled={updateclicked}
                        onChange={(e) => {
                            const selectedGroupId = parseInt(e.target.value);
                            if (selectedGroupId === 4) {
                                // Handle setting grpid to 4 here
                                // Example:
                                setGrpId(4); // Assuming setGrpId is your setter function for grpid
                            } else {
                                setGrpId(selectedGroupId); // Set grpid to the selected value normally
                            }
                        }}


                    >
                        {grouplist.length > 0 &&
                            grouplist.map((item, index) => (
                                <option
                                    key={index} value={item.grpid}>
                                    {updateclicked ? "" : item.groupname}

                                </option>
                            ))}
                    </select>

                    {grpid == 4 && (
                        <>                        <label htmlFor="">Field Name</label>
                            <input
                                type="text"
                                name="Owner Name"
                                placeholder="Enter Owner Name"
                                value={ownername}
                                onChange={(e) => setOwnername(e.target.value)}
                            />
                        </>
                    )}



                    {grpid != 4 && (
                        <>
                            <label htmlFor="Flat / Shop No :">Flat / Shop No :</label>
                            <input
                                type="text"
                                name="Flat / Shop No :"
                                placeholder="Enter Flat / Shop No"
                                value={flatshopno}
                                onChange={(e) => setFlatshopno(e.target.value)}
                            />

                            <label htmlFor="Name">Type of Property</label>
                            <select
                                style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                                name="Type of Property"
                                onChange={(e) => setPropertytype(e.target.value)}
                                value={propertytype}
                            >
                                <option value="">Select Property type</option>
                                <option value="Flat">Flat</option>
                                <option value="Shop">Shop</option>
                                <option value="Bungallow">Bungallow</option>
                                <option value="Villa">Villa</option>
                            </select>

                            <label htmlFor="Owner Name">Owner Name</label>
                            <input
                                type="text"
                                name="Owner Name"
                                placeholder="Enter Owner Name"
                                value={ownername}
                                onChange={(e) => setOwnername(e.target.value)}
                            />

                            <label htmlFor="Building Number">Building Number</label>
                            <input
                                type="text"
                                name="Building Number"
                                placeholder="Enter Building Number"
                                value={bldgno}
                                onChange={(e) => setBldgno(e.target.value)}
                            />

                            <label htmlFor="Wing Number">Wing Number:</label>
                            <input
                                type="text"
                                name="Wing Number"
                                placeholder="Enter Wing Number"
                                value={wingno}
                                onChange={(e) => setWingno(e.target.value)}
                            />

                            <label htmlFor="Area">Area</label>
                            <input
                                type="text"
                                name="Area"
                                placeholder="Enter Area"
                                value={area}
                                onChange={(e) => setArea(e.target.value)}
                            />

                            <label htmlFor="tenant">Tenant:</label>
                            <div style={{ marginBottom: '15px' }}>
                                <input
                                    type="radio"
                                    name="tenant"
                                    checked={tenant === true}
                                    onChange={() => setTenant(true)}
                                    style={{ margin: '0', padding: '0', width: '10%' }}
                                />
                                <span>Yes</span>
                                <input
                                    type="radio"
                                    name="tenant"
                                    checked={tenant === false}
                                    onChange={() => setTenant(false)}
                                    style={{ margin: '0', padding: '0', width: '10%' }}
                                />
                                <span>No</span>
                            </div>

                            <label htmlFor="vehicles">Vehicles:</label>
                            <input
                                type="number"
                                placeholder="Enter Number of Two Wheelers"
                                onChange={(e) => setTwowheeler(e.target.value)}
                                value={twowheeler}
                            />
                            <input
                                type="number"
                                placeholder="Enter Number of Four Wheelers"
                                onChange={(e) => setFourwheeler(e.target.value)}
                                value={fourwheeler}
                            />

                            <label htmlFor="Main Mobile Number">Main Mobile Number</label>
                            <input
                                type="text"
                                name="Main Mobile Number"
                                placeholder="Enter Main Mobile Number"
                                value={mainmobileno}
                                onChange={(e) => setMainmobileno(e.target.value)}
                            />

                            <label htmlFor="Whatsapp">Whatsapp</label>
                            <input
                                type="text"
                                name="Whatsapp"
                                placeholder="Enter Whatsapp"
                                value={whatsapp}
                                onChange={(e) => setWhatsapp(e.target.value)}
                            />

                            <label htmlFor="Occupation">Occupation</label>
                            <input
                                type="text"
                                name="Occupation"
                                placeholder="Enter Occupation"
                                value={occupation}
                                onChange={(e) => setOccupation(e.target.value)}
                            />

                            <label htmlFor="Building Bank Name">Building Bank Name</label>
                            <input
                                type="text"
                                name="Building Bank Name"
                                placeholder="Enter Building Bank Name"
                                value={bldgbankname}
                                onChange={(e) => setBldgbankname(e.target.value)}
                            />

                            <label htmlFor="Building Bank Account">Building Bank Account</label>
                            <input
                                type="text"
                                name="Building Bank Account"
                                placeholder="Enter Building Bank Account"
                                value={bldgbankaccountno}
                                onChange={(e) => setBldgbankaccountno(e.target.value)}
                            />

                            <label htmlFor="Building Bank Ifsc">Building Bank Ifsc</label>
                            <input
                                type="text"
                                name="Building Bank Ifsc"
                                placeholder="Enter Building Bank Ifsc"
                                value={bldgbankifsc}
                                onChange={(e) => setBldgbankifsc(e.target.value)}
                            />

                            <label htmlFor="Opening Balance">Opening Balance</label>
                            <input
                                type="text"
                                name="Opening Balance"
                                placeholder="Enter Opening Balance"
                                value={openingbalance}
                                onChange={(e) => setOpeningbalance(e.target.value)}
                            />

                            <label htmlFor="Closing Balance">Closing Balance</label>
                            <input
                                type="text"
                                name="Closing Balance"
                                placeholder="Enter Closing Balance"
                                value={closingbalance}
                                onChange={(e) => setClosingbalance(e.target.value)}
                            />
                        </>
                    )}



                    {updateclicked ? (
                        <button type="submit">Update</button>
                    ) : (
                        <button type="submit">Add</button>
                    )}

                </form>
            </div>
            <Header />

            <Sidebar />
        </StyledLedger>
    )
}

const StyledLedger = styled.div`
    .container{
    position: relative;
    left: 15%;
    width: 85%;
    min-height: 100vh;
    padding: 20vh 10%;


   


}

.template-container{
    margin-left: 85%;
    margin-bottom: 2vh;
    
}


#template-btn{
    width: 90%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: white;
    border-radius: 10px;

}

.sub-container{
    width: 100%;
    background-color: white;
    padding: 4vh 5%;
    box-shadow: 0px 4px 8px 0px #E2CACB; 


}

.header{
    display: flex;
    justify-content: space-between;
}

.work-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

   
}

#search{
    padding: 2px 10px;
    height: 4vh;
    border-radius: 10px;
    border: 2px solid #8E3B3E;

}

#search:focus{
    border: 2px solid #8E3B3E;
    outline: none;

}



#add-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;


}

#add-dropbox{
    right: -2%;
    margin-top: 6vh;
    position: absolute;
    width: 8vw;
    border-radius: 10px;
    z-index: 1;
    background-color: yellow;
    background-color: #8E3B3E;
    border: 1px solid #fff;


}

.add-dropbox-btns{
    width: 100%;
    background-color: #8E3B3E;
    border: none;
    border-radius: 10px;
    min-height: 3vh;

    color: #fff;
}


#download-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#upload-excel-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#send-mail-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

#heading{
    font-size: 2rem;
}



.slider{
    width: 20%;
    height: 100vh;
    position: absolute;
    right: 0;
    display: ${props => (props.slideropen ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    top: 0;
    background-color: #FDD5D6;
    transition: right 0.3s ease;
    z-index: 1;
    overflow-y: scroll;
    padding: 0px 1%;
}

.icon{
    display: flex;
    align-items: center;
    position: relative;
    top: 2vh;
    left: 5%;
    font-size: 1.2rem;

}

    #closeicon{
        
        font-size: 2rem;
        color: #8E3B3E;

    }

    
.slider-form {
    max-width: 400px;
    margin: 5vh auto 0 auto;
}

.slider-form label {
    display: block;
    margin-bottom: 5px;
}

.slider-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.slider-form button {
    width: 100%;
    padding: 10px;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 30%;
    z-index: 2;
    width: 40%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
}

#overlayaddexcelbox{
    position: absolute;
    top: 30vh;
    left: 40%;
    z-index: 2;
    width: 20%;
    height: 15vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
    
}

.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#excelbox-submit-btn{
    width: 40%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 10px;
}

#dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

.overlaymessagebox{
    position: absolute;
    top: 40vh;
    left: 35%;
    z-index: 2;
    width: 30%;
    padding: 2vh 0px;
    min-height: 30vh;
    background-color: #FDD5D6;
    display: grid;
    place-items: center;
}

.overlay-message-input{
    width: 80%;
    padding: 2px;
    margin-bottom: 2vh;
    
}

.overlay-email-submit{
    width: 80%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: #fff;


}

#emailmessageicon{
    position: absolute;
    top: 1vh;
    left: 2%;
    font-size: 1.8rem;
    color: #8E3B3E;

}

.msg-checkboxes{
    width: 80%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}



@media screen and (max-width: 480px) {
        
    .container{
    margin-top: 5vh;
    position: relative;
    left: 0;
    width: 100%;
    min-height: 100vh;
    padding: 5vh 5%;
    overflow-x: hidden;

    }

    
    .work-buttons{
        font-size: 0.7rem;
        justify-content: flex-end;
    }


    #search{
    width: 35%;
    padding: 2px 10px;
    height: 4vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    text-align: center;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;

    
    
    
    }

    #send-mail-btn{
    margin-left: 1vw;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;
    
    
    }

    #heading{
        font-size: 1rem;
    }

    .slider{
        width: 100%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 30vh;
    left: 20%;
    z-index: 2;
    width: 60%;
    height: 10vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1rem;
    display: grid;
    place-items: center;
}



.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#dlt-btns{
    width: 25%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}



}


@media screen and (min-width: 768px) and (max-width: 1020px) 
{
    .container{
        position: relative;
        left: 20%;
        width: 80%;
        min-height: 100vh;
        padding: 20vh 5%;
    


    }



    #heading{
        font-size: 1.5rem;
    }

    .slider{
        width: 40%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 25%;
    z-index: 2;
    width: 50%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1.5rem;
    display: grid;
    place-items: center;    

    }

    .dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


    }

    #dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    }

    #search{
    height: 6vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    height: 6vh;
    font-size: 0.8rem;



    

    }

    #send-mail-btn{
    height: 6vh;
    font-size: 0.8rem;
    width: 30%;

    
    
    }


}
`

export default Ledger