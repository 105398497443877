import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';


// imports for mainpage
import Mainpage from './Mainpage';

// import for different pages
import Login from './pages/Login';
import Forgot_password from './pages/Forgot_password';
import Housing_federation from './pages/Housing_federation';
import Buildings from './pages/Buildings';
import Ledger from './pages/Ledger';
import Admin from './pages/Admin';
import Bill_heads from './pages/Bill_heads';
import Voucher from './pages/Voucher';
import Bill_generation from './pages/Bill_generation';
import Backup from './pages/Backup';








const App = () => {

  // this conatains the role or level of an admin
  const adminState = useSelector(state => state.admin.admin);


  return (
    <>
      <BrowserRouter>
        <Routes>

          <Route path='/' element={<Login />} />
          <Route path='/Forgotpass' element={<Forgot_password />} />
          <Route path='/Dashboard' element={<Mainpage />} />
          {(adminState === 1) && <Route path='/Housingfed' element={<Housing_federation />} />}

          <Route path='/Buildings' element={<Buildings />} />
          <Route path='/Ledger' element={<Ledger />} />
          {(adminState === 1) && <Route path='/Admin' element={<Admin />} />}
          <Route path='/Billgeneration' element={<Bill_generation />} />
          <Route path='/Billheads' element={<Bill_heads />} />
          <Route path='/Voucher' element={<Voucher /
          >} />
          <Route path='/Backup' element={<Backup /
          >} />




        </Routes>
      </BrowserRouter>

    </>

  )
}

export default App

