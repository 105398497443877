import React from 'react'
import styled from 'styled-components'

const Logo = () => {
  return (
    <StyledLogo>
      <img id='logo' src="./Resources/logo.png" alt="logo" />
      <span id='logo-txt'>AccWizz</span>
    </StyledLogo>
  )
}

const StyledLogo = styled.div`
display: flex;
align-items: center;


#logo{
  width: 4rem;
  cursor: pointer;

}
#logo-txt{
  font-size: 2rem;
  cursor: pointer;

}

@media screen and (min-width: 980px) and (max-width: 1080px) {
  #logo{
  width: 3rem;
  

}
#logo-txt{
  font-size: 1.3rem;


}


}


@media screen and (min-width: 720px) and (max-width: 980px) {
  #logo{
  width: 2rem;
  

}
#logo-txt{
  font-size: 1rem;


}


}

`

export default Logo