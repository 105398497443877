import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../Sidebar';
import styled from 'styled-components';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faArrowRight, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useReactToPrint } from 'react-to-print';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from "xlsx";
import { secretKey, apiUrl } from '../constant';
import { encryptData, decryptData } from '../Functions/Data_protection';
import { handleExportExcel } from '../Functions/Excelprint';
import { RetrieveDemographicData } from '../Functions/RetrieveDemographic';
import { HandleDelete } from '../api/Deletion';
import { validateForm } from '../Functions/Form_validation';
import { handleAdd } from '../api/Addition';
import { handleFileUpload } from '../Functions/Excelupload';
import { handlefilter } from '../Functions/FilterRecords';

import Header from '../Components/Mainpage_componnets/Header';



import { setAdminList } from '../store/slices/AdminSlice';
import { addAdminToList } from '../store/slices/AdminSlice';
import { updateAdminInList } from '../store/slices/AdminSlice';











const Admin = () => {



    const buildinglistState = useSelector(state => state.building.buildinglist);

    const adminidState = useSelector(state => state.admin.userid);

    const adminlistState = useSelector(state => state.admin.adminlist);

    const dispatch = useDispatch();

    const [originalRecords, setOriginalRecords] = useState([]);
    const [records, setRecords] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [slideropen, setSlideropen] = useState(false);
    const [LedId, setLedId] = useState('');
    const [HsgFedId, setHsgfedId] = useState('');
    const [BldgId, setBldgId] = useState('');

    const [username, setUsername] = useState();
    const [userid, setUserid] = useState();
    const [access, setAccess] = useState([]);
    const [level, setLevel] = useState();
    const [mobileno, setMobileno] = useState();
    const [whatsapp, setWhatsapp] = useState();
    const [password, setPassword] = useState();
    const [email, setEmail] = useState();
    const [geographicdata, setGeographicdata] = useState([]);
    const [pincode, setPincode] = useState('');
    const [role, setRole] = useState('');
    const [area, setArea] = useState();






    const [createdby, setCreatedby] = useState('');
    const [modifiedby, setModifiedby] = useState('');

    const [federationlist, setFederationlist] = useState([]);
    const [buildinglist, setBuildinglist] = useState([]);



    // States for handling the message box and related things
    const [smsboxexpanded, setSmsboxexpanded] = useState(true);
    const [emailboxexpanded, setEmailboxexpanded] = useState(false);
    const [whatsappboxexpanded, setWhatappboxexpanded] = useState(false);
    const [smsMessage, setSmsMessage] = useState('');
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [emailMessage, setEmailMessage] = useState('');

    const [addbtnclicked, setAddbtnclicked] = useState(false);

    // State to store the payload of message 
    const [messageobject, setMessageobject] = useState([]);



    // States to handle message related things
    const [messageboxexpand, setMessageboxexpand] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);


    //References
    const hsgfedref = useRef(null);
    const bldgidref = useRef(null);
    const levelref = useRef(null);
    const propertytyperef = useRef(null);
    const pincodeRef = useRef(null);





    const [updateclicked, setUpdateclicked] = useState(false);
    const [Deleteclicked, setDeleteclicked] = useState(false);


    useEffect(() => {
        handleLoad();
    }, []);

    const openslider = () => {
        setSlideropen(true);
    };

    const columns = [
        {
            name: '',
            selector: row => (
                <div onClick={() => {
                    setSlideropen(false);
                    setMessageboxexpand(false);
                    ;
                    setDeleteclicked(true);
                    setSelectedRow(row);

                    setUsername(row.username);
                }}>
                    <button style={{
                        border: 'none',
                        background: 'none',
                        cursor: 'pointer'
                    }}>
                        <FontAwesomeIcon icon={faClose} style={{ color: '#8E3B3E', fontSize: '1.5rem' }} />
                    </button>
                </div>
            ),
            sortable: false, // This column does not need to be sortable
            width: '50px',
            wrap: true
        },
        { name: 'Username', selector: row => <div onClick={() => { Updateclicked(row) }}>{row.username}</div>, sortable: true, width: '150px', wrap: true },
        { name: 'Email', selector: row => row.email, sortable: true, wrap: true, width: '150px' },
        { name: 'Level', selector: row => row.level, sortable: true, wrap: true, width: '250px' },

    ];



    const handleLoad = async () => {
        try {
            const response = await axios.get(`${apiUrl}getadmins`);
            const decryptedData = decryptData(response.data.payload, secretKey);
            

            dispatch(setAdminList(decryptedData));
            setOriginalRecords(decryptedData);
            setRecords(decryptedData);

        } catch (error) {
            console.log(error);
        }

    };


    useEffect(() => {
        setOriginalRecords(adminlistState);
        setRecords(adminlistState);
    }, [adminlistState])



    const Handlepincodesearch = async () => {
        const pincodeValue = pincodeRef.current.value;
        console.log(pincodeValue);
        const demographicData = await RetrieveDemographicData(pincodeValue);
        if (demographicData) {
            setGeographicdata(demographicData);
            setArea(demographicData[0].PostOffice[0].Name)


        };

    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setAccess([...access, value]);
        } else {
            setAccess(access.filter(item => item !== value));
        }
    };




    const resetControls = () => {
        handleLoad();
        setHsgfedId('');
        setBldgId('');
        setUsername('');
        setAccess([]);
        setLevel('');
        setMobileno('');
        setWhatsapp('');
        setEmail('');



    };

    const Updateclicked = (row) => {
        setSelectedRow(row); // Storing the selected row data
        setDeleteclicked(false);
        setMessageboxexpand(false)
        setSlideropen(true);
        setUpdateclicked(true);



        // populate data to input
        setUsername(row.username);
        setLevel(row.level);
        setMobileno(row.mobileno);
        setEmail(row.email);
        setWhatsapp(row.whatsapp);



    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        let payload;

        payload = {
            username: username,
            email: email,
            role: role,
            access: access,
            password: password,
            userid: userid,
            createdby: adminidState,
            modifiedby: adminidState,
        };

        console.log(`This is the payload given:`, payload);


        // let validationErrors = {};
        // if (Array.isArray(payload)) {
        //     validationErrors = payload.map(validateForm).flat();
        // } else {
        //     validationErrors = validateForm(payload);
        // }


        // if (Object.keys(validationErrors).length > 0) {
        //     console.log(validationErrors);
        //     setErrors(validationErrors);
        //     return;
        // }

        // setErrors({});

        try {
            const response = await handleAdd(payload, 'addadmin');
            console.log(response);

            if (response[0].status) {
                toast.success(`${response[0].message}`, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setSlideropen(false);
                if (response[0].update) {
                    dispatch(updateAdminInList(response[0].data));

                }
                else {
                    response.forEach(item => {
                        dispatch(addAdminToList(item.data));
                    });


                }


                

            }
        } catch (error) {
            console.error(error);
        }
        finally {
            payload = null;


        }
    };


    const handleRowSelected = state => {
        const selectedRows = state.selectedRows;
        setSelectedRows(selectedRows);
        setMessageobject(selectedRows);



        // const selectedEmails = state.selectedRows.map(row => row.email);
        // const selectedEmails = state.selectedRows.map(row => row.email);

    };

    const handleSendEmail = () => {
        const messagepayload = messageobject.map(row => ({
            email: row.email,
            bldgname: row.name,
            pocname: row.pocname,
            pocnumber: row.pocnumber,
            pocwhatsapp: row.pocwhatsapp,
            sms: smsMessage,
            whatsapp: whatsappMessage,
            emailmsg: emailMessage

        }))

        console.log(messagepayload);
    };

    const HandleDeleteclicked = async () => {

        const deletedresponsedata = await HandleDelete(username, "deleteadmin");
        console.log(deletedresponsedata);
        if (deletedresponsedata.status) {
            toast.success(deletedresponsedata.message)
            setDeleteclicked(false);
            handleLoad();
        }
    };









    return (
        <StyledLedger slideropen={slideropen}>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <div className="container">
                <div className="sub-container">
                    <header className='header'>
                        <span id='heading'>Admin list</span>
                        <div className="work-buttons">

                            <FontAwesomeIcon id='download-btn' icon={faFileExcel} onClick={() => handleExportExcel(selectedRows)} />


                            {/* <FontAwesomeIcon id='download-btn' icon={faFilePdf} onClick={handlePrint} /> */}

                            <input type="text" placeholder='search' id='search' onChange={(event) => { handlefilter(event, originalRecords, setRecords) }} />
                            <button id='send-mail-btn' onClick={() => {
                                if (messageobject.length >= 1) {
                                    setSlideropen(false);
                                    setDeleteclicked(false);
                                    setMessageboxexpand(true)
                                }
                                else {
                                    alert("Select the rows first")
                                }

                            }}>Send Message</button>

                            <button id='add-btn' onClick={() => {
                                setMessageboxexpand(false);
                                setDeleteclicked(false);
                                openslider();
                                setUpdateclicked(false); setMessageboxexpand(false); resetControls();

                            }}>Add New

                            </button>




                        </div>
                    </header>
                    <div id="table-component">
                        <DataTable
                            // ref={tableRef}
                            columns={columns}
                            data={records}
                            selectableRows
                            onSelectedRowsChange={handleRowSelected}
                            pagination
                            paginationPerPage={5}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            className='data-table'
                        ></DataTable>
                    </div>


                </div>

                {Deleteclicked && (
                    <div className="overlaydltbox">
                        <p>Are u sure want to Delete ?</p>
                        <div className="dltresponseboxes">
                            <button id='dlt-btns' onClick={HandleDeleteclicked} >Delete</button>
                            <button id='dlt-btns' onClick={() => setDeleteclicked(false)} >Cancel</button>
                        </div>
                    </div>
                )}

                {messageboxexpand && (
                    <div className="overlaymessagebox">
                        <FontAwesomeIcon
                            icon={faClose}
                            id="emailmessageicon"
                            onClick={() => { setMessageboxexpand(false); }}
                        />

                        <h2 style={{ color: '#8E3B3E' }}>Send Message</h2>
                        <div className="msg-checkboxes">
                            <label htmlFor="smsboxexpanded">Send SMS</label>
                            <input
                                type="checkbox"
                                id="sendSMS"
                                checked={smsboxexpanded}
                                onChange={() => setSmsboxexpanded(!smsboxexpanded)}
                            />
                            <label htmlFor="sendEmail">Send Email</label>
                            <input
                                type="checkbox"
                                id="sendEmail"
                                checked={emailboxexpanded}
                                onChange={() => setEmailboxexpanded(!emailboxexpanded)}
                            />
                            <label htmlFor="sendWhatsapp">Send Whatsapp</label>
                            <input
                                type="checkbox"
                                id="sendWhatsapp"
                                checked={whatsappboxexpanded}
                                onChange={() => setWhatappboxexpanded(!whatsappboxexpanded)}
                            />
                        </div>
                        {smsboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter SMS"
                                className="overlay-message-input"
                                value={smsMessage}
                                onChange={(e) => setSmsMessage(e.target.value)}
                            ></textarea>
                        )}
                        {whatsappboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter Whatsapp Message"
                                className="overlay-message-input"
                                value={whatsappMessage}
                                onChange={(e) => setWhatsappMessage(e.target.value)}
                            ></textarea>
                        )}
                        {emailboxexpanded && (
                            <textarea
                                rows={5}
                                placeholder="Enter Email Message"
                                className="overlay-message-input"
                                value={emailMessage}
                                onChange={(e) => setEmailMessage(e.target.value)}
                            ></textarea>
                        )}
                        <button
                            className="overlay-email-submit"
                            onClick={handleSendEmail}
                        >
                            Submit
                        </button>
                    </div>
                )}

            </div>
            <div className="slider">
                <div className="icon">
                    <FontAwesomeIcon icon={faClose} id='closeicon' onClick={() => setSlideropen(false)} />
                    <p style={{ marginLeft: '2%' }}>Admin</p>
                </div>
                <form onSubmit={handleSubmit} className='slider-form'>
                    <div>
                        <label htmlFor="username">Username :</label>
                        <input
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className='form-inputs'
                            disabled={updateclicked}
                        />

                    </div>

                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='form-inputs'
                        />
                    </div>

                    <div>
                        <label htmlFor="role">Role</label>
                        <select
                            id="role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className='form-inputs'
                            style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                        >
                            <option value="">Select Role</option>
                            <option value="1">Super-Admin</option>
                            <option value="2">Sub-Admin</option>
                            <option value="3">Manager</option>
                            <option value="4">Accountant</option>
                        </select>
                    </div>

                    <div>
                        <label htmlFor="pincode">Pincode:</label>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <input
                                ref={pincodeRef}
                                style={{ width: '88%' }}
                                type="text"
                                id="pincode"
                                name="PIN CODE"
                                placeholder="Enter Pincode"
                                value={pincode}
                                onChange={(e) => setPincode(e.target.value)}
                            />
                            <button
                                type='button'
                                style={{ width: '10%', height: '3.5vh' }}
                                onClick={Handlepincodesearch}
                            >
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                        <select
                            style={{ width: '100%', height: '3.5vh', marginBottom: '15px' }}
                            name="AREA"
                            id="area_dropdown"
                            onChange={(e) => {
                                const selectedArea = e.target.value;

                                setArea(selectedArea);
                            }}
                        >
                            {geographicdata.length > 0 &&
                                geographicdata[0].PostOffice.map((item, index) => (
                                    <option key={index} value={item.Name}>
                                        {item.Name}
                                    </option>
                                ))}
                        </select>
                    </div>



                    <label htmlFor="access">Access :</label>
                    <hr />
                    <label>Buildings:</label>
                    <div>
                        {buildinglistState
                            .filter(building => {
                                return building.area && building.area.trim() !== '' && area && building.area.toLowerCase().includes(area.toLowerCase());
                            })
                            .map(building => (
                                <div key={building.bldid} style={{ display: 'flex' }}>
                                    <input
                                        style={{ maxWidth: '10%' }}
                                        type="checkbox"
                                        value={building.bldgid}
                                        onChange={handleCheckboxChange}
                                    />
                                    <label htmlFor={building.bldgname}>{building.bldgname}</label>
                                </div>
                            ))}
                    </div>



                    <div>
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='form-inputs'
                            disabled={updateclicked}
                        />

                    </div>

                    {updateclicked ? (
                        <button type="submit">Update</button>
                    ) : (
                        <button type="submit">Add</button>
                    )}
                </form>



            </div>
            <Header />
            <Sidebar />
        </StyledLedger>
    )
}

const StyledLedger = styled.div`
    .container{
    position: relative;
    left: 15%;
    width: 85%;
    min-height: 100vh;
    padding: 20vh 10%;
   
}

.sub-container{
    width: 100%;
    background-color: white;
    padding: 4vh 5%;
    box-shadow: 0px 4px 8px 0px #E2CACB; 


}

.header{
    display: flex;
    justify-content: space-between;
}

.work-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

   
}

#search{
    padding: 2px 10px;
    height: 4vh;
    border-radius: 10px;
    border: 2px solid #8E3B3E;

}

#search:focus{
    border: 2px solid #8E3B3E;
    outline: none;

}



#add-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;


}

#add-dropbox{
    right: -2%;
    margin-top: 6vh;
    position: absolute;
    width: 8vw;
    border-radius: 10px;
    z-index: 1;
    background-color: yellow;
    background-color: #8E3B3E;
    border: 1px solid #fff;


}

.add-dropbox-btns{
    width: 100%;
    background-color: #8E3B3E;
    border: none;
    border-radius: 10px;
    min-height: 3vh;

    color: #fff;
}


#download-btn{
    margin-right: 1vw;
    border-radius: 10px;
    color: #8E3B3E;
    border: none;
    font-size: 1.8rem;
}

#send-mail-btn{
    margin-left: 1vw;
    text-align: center;
    height: 4vh;
    border-radius: 10px;
    width: 8vw;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

#heading{
    font-size: 2rem;
}



.slider{
    width: 20%;
    height: 100vh;
    position: absolute;
    right: 0;
    display: ${props => (props.slideropen ? 'block' : 'none')};
    transition: transform 0.3s ease-in-out;
    top: 0;
    background-color: #FDD5D6;
    transition: right 0.3s ease;
    z-index: 1;
    overflow-y: scroll;
    padding: 0px 1%;
}

.icon{
    display: flex;
    align-items: center;
    position: relative;
    top: 2vh;
    left: 5%;
    font-size: 1.2rem;

}

    #closeicon{
        
        font-size: 2rem;
        color: #8E3B3E;

    }

    
.slider-form {
    max-width: 400px;
    margin: 5vh auto 0 auto;
}

.slider-form label {
    display: block;
    margin-bottom: 5px;
}

.slider-form input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.slider-form button {
    width: 100%;
    padding: 10px;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 30%;
    z-index: 2;
    width: 40%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
}

#overlayaddexcelbox{
    position: absolute;
    top: 30vh;
    left: 40%;
    z-index: 2;
    width: 20%;
    height: 15vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 2rem;
    display: grid;
    place-items: center;
    
}

.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#excelbox-submit-btn{
    width: 40%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    border-radius: 10px;
}

#dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}

.overlaymessagebox{
    position: absolute;
    top: 40vh;
    left: 35%;
    z-index: 2;
    width: 30%;
    padding: 2vh 0px;
    min-height: 30vh;
    background-color: #FDD5D6;
    display: grid;
    place-items: center;
}

.overlay-message-input{
    width: 80%;
    padding: 2px;
    margin-bottom: 2vh;
    
}

.overlay-email-submit{
    width: 80%;
    height: 4vh;
    background-color: #8E3B3E;
    border: none;
    color: #fff;


}

#emailmessageicon{
    position: absolute;
    top: 1vh;
    left: 2%;
    font-size: 1.8rem;
    color: #8E3B3E;

}

.msg-checkboxes{
    width: 80%;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
}



@media screen and (max-width: 480px) {
        
    .container{
    margin-top: 5vh;
    position: relative;
    left: 0;
    width: 100%;
    min-height: 100vh;
    padding: 5vh 5%;
    overflow-x: hidden;

    }

    
    .work-buttons{
        font-size: 0.7rem;
        justify-content: flex-end;
    }


    #search{
    width: 35%;
    padding: 2px 10px;
    height: 4vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    text-align: center;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;

    
    
    
    }

    #send-mail-btn{
    margin-left: 1vw;
    height: 4vh;
    width: 22.5%;
    font-size: 0.7rem;
    
    
    }

    #heading{
        font-size: 1rem;
    }

    .slider{
        width: 100%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 30vh;
    left: 20%;
    z-index: 2;
    width: 60%;
    height: 10vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1rem;
    display: grid;
    place-items: center;
}



.dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


}

#dlt-btns{
    width: 25%;
    height: 3vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
}



}


@media screen and (min-width: 768px) and (max-width: 1020px) 
{
    .container{
        position: relative;
        left: 20%;
        width: 80%;
        min-height: 100vh;
        padding: 20vh 5%;
    


    }



    #heading{
        font-size: 1.5rem;
    }

    .slider{
        width: 40%;
        z-index: 1;
    }

    .overlaydltbox{
    position: absolute;
    top: 40vh;
    left: 25%;
    z-index: 2;
    width: 50%;
    height: 20vh;
    background-color: #FDD5D6;
    color: #8E3B3E;
    font-size: 1.5rem;
    display: grid;
    place-items: center;    

    }

    .dltresponseboxes{
    display: flex;
    justify-content: space-evenly;
    width: 80%;


    }

    #dlt-btns{
    width: 25%;
    height: 4vh;
    background-color: #8E3B3E;
    color: #fff;
    border: none;
    }

    #search{
    height: 6vh;
    border: 2px solid #8E3B3E;

    }

    #add-btn{
    height: 6vh;
    font-size: 0.8rem;



    

    }

    #send-mail-btn{
    height: 6vh;
    font-size: 0.8rem;
    width: 30%;

    
    
    }


}
`

export default Admin