import React from 'react'
import Sidebar from '../Sidebar'
import Header from '../Components/Mainpage_componnets/Header'
import styled from 'styled-components'
import axios from 'axios'
import { apiUrl } from '../constant'

const backupclicked = async () => {
    try {
        console.log("Triggered");

        const response = await axios.post(`${apiUrl}Backup`);
        if (response.data.status) {
            alert("Backup Succesfull")
        }
    } catch (error) {
        console.error("Error:", error); // Handles any errors
    }
};

const command = `mongorestore --uri="mongodb://localhost:27017/<your-databasename>" <path to your downloaded file>/accwizz
`;

const download = async () => {
    try {
        const response = await axios.get(`${apiUrl}Downloadbackup`, {
            responseType: 'blob', // Important for handling binary data
        });

        // Create a link element and trigger the download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'folder.zip'); // The name for the downloaded file
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error("Error downloading the backup:", error);
    }
};


const Backup = () => {
    return (
        <StyledBackup>
            <div className="container">
                <div className="sub-container">
                    <b><p style={{ textAlign: 'center' }}>Backup & Restore</p></b><br />
                    <p>1. Click the Backup button below to create a backup of database.</p>
                    <button onClick={backupclicked}>
                        Backup
                    </button>
                    <p>2. Wait till the upload successful prompt.</p>
                    <p>3. Then Click the download Button.</p>
                    <button onClick={download}>
                        Download
                    </button>
                    <p>4. A zip file will be downloaded on your pc.</p>
                    <p>5. Extract the zip file in folder and keep the location.</p>
                    <p>6. Run the below Command on your command propmt / Powershell.</p>
                    <code><p>{command}</p></code>
                    <div className="description">

                    </div>
                </div>

            </div>
            <Header />
            <Sidebar />
        </StyledBackup>
    );
};

const StyledBackup = styled.div`


    .container{
        position: relative;
    left: 15%;
    width: 85%;
    min-height: 100vh;
    display: grid;
    place-content: center;
        
    }

    .sub-container{
        position: relative;
        background-color: #FDD5D6;
        width: 20vw;
        padding: 4vh 5%;
        box-shadow: 0px 4px 8px 0px #E2CACB; 
    }


`

export default Backup